/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import axios from "../../../axios/axios";
import TableHeader from "../../common/TableHeader";
import ReactApexChart from "react-apexcharts";

function KeywordDetailsPopUp(props) {
    const [keywordDetails, setKeywordDetails] = useState();
    useEffect(() => {
        axios
            .get(
                `/fonix-ivr-entries/keyword/${props.selectedKeywordRow.id}/details`
            )
            .then((r) => {
                setKeywordDetails(r.data);
            });
    }, []);

    return (
        <div
            className="popup"
            style={{ zIndex: "2001", overflow: "scroll", maxHeight: "100vh" }}
        >
            <div
                className="d-flex content card align-items-center justify-content-around p-5"
                style={{ minWidth: "90%" }}
            >
                <div
                    className="position-absolute top-0 end-0 me-3 mt-2 fe fe-x-circle"
                    style={{ height: "20px", width: "20px" }}
                    onClick={() => props.onCloseClicked()}
                ></div>

                <Row>
                    <h2>KEYWORD : {props.selectedKeywordRow.keyword}</h2>
                </Row>
                {keywordDetails ? (
                    <Container
                        style={{
                            overflow: "scroll",
                            maxHeight: "80vh",
                            maxWidth: "80vw",
                        }}
                    >
                        <Row>
                            <Col
                                sm="12"
                                lg="12"
                                className="d-flex flex-column p-0"
                            >
                                <div className="container-fluid tableContainer m-0 p-0">
                                    <div
                                        className="card"
                                        style={{ overflow: "auto" }}
                                    >
                                        <div className="card-header">
                                            <div className="row align-items-center">
                                                <div className="col">
                                                    <h3 className="card-header-title">
                                                        Keyword Details
                                                    </h3>
                                                </div>
                                                <div
                                                    className="col d-flex"
                                                    style={{
                                                        alignContent: "end",
                                                        justifyContent: "end",
                                                    }}
                                                ></div>
                                            </div>
                                        </div>

                                        <Table
                                            className="table table-sm table-nowrap card-table"
                                            responsive="sm"
                                        >
                                            <thead>
                                                <tr>
                                                    <TableHeader id="1" name="Keyword"></TableHeader>
                                                    <TableHeader id="2" name="Description"></TableHeader>
                                                    <TableHeader id="3" name="Active From "></TableHeader>
                                                    <TableHeader id="4" name="Template"></TableHeader>
                                                    <TableHeader id="5" name="Free Entries Enabled"></TableHeader>
                                                    <TableHeader id="6" name="Free Entries"></TableHeader>
                                                    <TableHeader id="7" name="Entries Req."></TableHeader>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <td>
                                                        {keywordDetails.keyword}
                                                    </td>
                                                    <td>
                                                        {
                                                            keywordDetails.description
                                                        }
                                                    </td>
                                                    <td>
                                                        {new Date(
                                                            keywordDetails.useFrom *
                                                                1000
                                                        ).toLocaleString()}
                                                    </td>
                                                    <td>
                                                        {keywordDetails.template ==
                                                        ""
                                                            ? "None"
                                                            : keywordDetails.template}
                                                    </td>
                                                    <td>
                                                        {keywordDetails
                                                            .keywordSettings
                                                            .freeEntriesEnabled
                                                            ? "TRUE"
                                                            : "FALSE"}
                                                    </td>
                                                    <td>
                                                        {
                                                            keywordDetails
                                                                .keywordSettings
                                                                .freeEntriesAwarded
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            keywordDetails
                                                                .keywordSettings
                                                                .entriesRequiredForFreeEntries
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </Col>
                            <Col sm="12" lg="12" className="p-0">
                                <div className="container-fluid tableContainer m-0 p-0">
                                    <div
                                        className="card"
                                        style={{ overflow: "auto" }}
                                    >
                                        <div className="card-header">
                                            <div className="row align-items-center">
                                                <div className="col">
                                                    <h3 className="card-header-title">
                                                        Effective Routing
                                                    </h3>
                                                </div>
                                                <div
                                                    className="col d-flex"
                                                    style={{
                                                        alignContent: "end",
                                                        justifyContent: "end",
                                                    }}
                                                ></div>
                                            </div>
                                        </div>

                                        <Table
                                            className="table table-sm table-nowrap card-table"
                                            responsive="sm"
                                        >
                                            <thead>
                                                <tr>
                                                    <TableHeader name="Outcome"></TableHeader>
                                                    <TableHeader name="Feature Code"></TableHeader>
                                                    <TableHeader name="Source"></TableHeader>
                                                    <TableHeader name="DType"></TableHeader>
                                                    <TableHeader name="DNumber"></TableHeader>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {keywordDetails.effectiveRouting.map(
                                                    (r) => {
                                                        return (
                                                            <tr key={r.outcome}>
                                                                <td>
                                                                    {r.outcome}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        r.featureCodeName
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {r.source}
                                                                </td>
                                                                <td>
                                                                    {r.dtype}
                                                                </td>
                                                                <td>
                                                                    {r.dnumber}
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12" lg="6" className="p-0">
                                <div
                                    style={{ padding: "5px !important" }}
                                    className="container-fluid tableContainer m-0 p-0"
                                >
                                    <div
                                        className="card"
                                        style={{ overflow: "auto" }}
                                    >
                                        <div className="card-header">
                                            <div className="row align-items-center">
                                                <div className="col">
                                                    <h3 className="card-header-title">
                                                        Entries
                                                    </h3>
                                                </div>
                                                <div
                                                    className="col d-flex"
                                                    style={{
                                                        alignContent: "end",
                                                        justifyContent: "end",
                                                    }}
                                                ></div>
                                            </div>
                                        </div>

                                        <Table
                                            className="table table-sm table-nowrap card-table"
                                            responsive="sm"
                                        >
                                            <thead>
                                                <tr>
                                                    <TableHeader name="Entry Status"></TableHeader>
                                                    <TableHeader name="Entry Type"></TableHeader>
                                                    <TableHeader name="Entry Count"></TableHeader>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {keywordDetails.entryCountBreakdown.map(
                                                    (ecb) => {
                                                        return (
                                                            <tr
                                                                key={
                                                                    ecb.entryStatus +
                                                                    "_" +
                                                                    ecb.freeEntry
                                                                }
                                                            >
                                                                <td>
                                                                    {
                                                                        ecb.entryStatus
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {ecb.freeEntry
                                                                        ? "FREE"
                                                                        : "PAID"}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        ecb.entryCount
                                                                    }
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </Col>
                            <Col
                                sm="12"
                                lg="6"
                                className="d-flex align-items-center justify-content-around p-0 m-0"
                            >
                                <div className="container-fluid tableContainer m-0 p-0">
                                    <div
                                        className="card"
                                        style={{ overflow: "auto" }}
                                    >
                                        <div className="card-header">
                                            <div className="row align-items-center">
                                                <div className="col">
                                                    <h3 className="card-header-title">
                                                        Entrant Stats
                                                    </h3>
                                                </div>
                                                <div
                                                    className="col d-flex"
                                                    style={{
                                                        alignContent: "end",
                                                        justifyContent: "end",
                                                    }}
                                                ></div>
                                            </div>
                                        </div>

                                        <Table
                                            className="table table-sm table-nowrap card-table"
                                            responsive="sm"
                                        >
                                            <thead>
                                                <tr>
                                                    <TableHeader name="Unique"></TableHeader>
                                                    <TableHeader name="Avg Entries"></TableHeader>
                                                    <TableHeader name="Greatest"></TableHeader>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <td>
                                                        {
                                                            keywordDetails.stats
                                                                .uniqueEntrantsCount
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            keywordDetails.stats
                                                                .averageEntriesPerEntrant
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            keywordDetails.stats
                                                                .greatestEntrantEntryCount
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </Col>
                            <Col
                                sm="12"
                                lg="12"
                                className="d-flex align-items-center justify-content-around p-0"
                            >
                                <Card className="p-5">
                                    <ReactApexChart
                                        options={{
                                            title: {
                                                text: "Entrant Entry Count Distribution",
                                                offsetX: 0,
                                                offsetY: 0,
                                                style: {
                                                    fontSize: "17px",
                                                    fontFamily:
                                                        '"Cerebri Sans", sans-serif;',
                                                },
                                            },
                                            dataLabels: {
                                                enabled: false,
                                            },
                                            chart: {
                                                type: "bar",
                                                toolbar: {
                                                    show: true,
                                                    tools: {
                                                        zoom: true,
                                                        zoomin: true,
                                                        zoomout: true,
                                                    },
                                                },
                                            },
                                            yaxis: {
                                                title: {
                                                    text: "Entrants",
                                                },
                                            },
                                            xaxis: {
                                                title: {
                                                    text: "",
                                                },
                                                categories:
                                                    keywordDetails.stats
                                                        .distribution
                                                        .entryCount,
                                                labels: {
                                                    type: "categories",
                                                    rotate: -45,
                                                    style: "",
                                                },
                                                tooltip: {
                                                    y: {
                                                        formatter: function (
                                                            val
                                                        ) {
                                                            return (
                                                                val + " enrants"
                                                            );
                                                        },
                                                    },
                                                },
                                            },
                                        }}
                                        series={[
                                            {
                                                name: "Entrants",
                                                data: keywordDetails.stats
                                                    .distribution.entrantCount,
                                            },
                                        ]}
                                        type="bar"
                                        width={600}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
}

export default KeywordDetailsPopUp;
