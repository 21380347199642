import React from "react";
import StatsPage from "../components/common/StatsPage";
import ServicesTable from "../components/webentries/services/ServicesTable";
import ActivityTable from "../components/webentries/activity/ActivityTable";

const WebEntries = () => {

    const buttonA = {text:"Service",component:<ServicesTable/>}
    const buttonB = {text:"Activity",component:<ActivityTable/>}
    return (
        <StatsPage
            title="Web Entries"
            pretitle="Entries"
            innerNav={[buttonA,buttonB]}
            render={<ServicesTable></ServicesTable>}
        />
    );
};

export default WebEntries;
