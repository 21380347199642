/* eslint-disable react/prop-types */

import React, { useEffect } from "react";
import { Button, Form, Row } from "react-bootstrap";

import axios from "../../../axios/axios";
import _ from "underscore";

function CreateKeywordPopUp(props) {
    

    const [templates, setTemplates] = React.useState();

    const [form, setForm] = React.useState({
        serviceId: props.service.serviceDetailsOverview.id,
        keywordDetails: {
            keyword: null,
            activeFrom: null,
            template: 0,
            description: "",
        },
        keywordSettings: { entriesRequiredForFreeEntries: 0, freeEntries: 0 },
    });


    function handleKeywordChange(k){
        const f = _.clone(form);
        f.keywordDetails.keyword = k;
        setForm(f);
    }
    function handleKeywordDescriptionChange(d){
        const f = _.clone(form);
        f.keywordDetails.description = d;
        setForm(f);
    }

    function handleFreeEntriesChange(d){
        const f = _.clone(form);
        f.keywordSettings.freeEntries = d;
        setForm(f);
    }

    function handleEntriesRequiredChange(d){
        const f = _.clone(form);
        f.keywordSettings.entriesRequiredForFreeEntries = d;
        setForm(f);
    }

    function handleActiveFromChange(d){
        console.log(d)
        const f = _.clone(form);
        f.keywordDetails.activeFrom = d
        setForm(f);
    }

    function handleTemplateSelectChange(t){
        console.log(t)
        const f = _.clone(form);
        f.keywordDetails.template = t
        setForm(f);
    }

    function submitForm(){
        const err = [];
        if(!form.keywordDetails.keyword){
            err.push("A Keyword is required.")
        }
        if(!form.keywordDetails.activeFrom){
            err.push("An Active From Time is required.")
        }
        
        if(err.length>0){
            alert(err.join("\n"))
        }

        axios.post("/fonix-ivr-entries/keywords",JSON.stringify(form)
        ,{
            method:'POST',
            headers:{'Content-Type':'application/json'}    
        })
        .then(()=>{
            alert("Keyword Created!")
            props.onCloseClicked()
        }).catch((e)=>{
            alert(e)
        })
        
    }

    useEffect(() => {
        axios
            .get(
                `/fonix-ivr-entries/templates?serviceId=${props.service.serviceDetailsOverview.id}`
            )
            .then((r) => {
                setTemplates(r.data);
            });
    }, []);

    return (
        <div
            className="popup"
            style={{ zIndex: "2001", overflow: "scroll", maxHeight: "100vh" }}
        >
            <div
                className="d-flex content card align-items-center justify-content-around p-5"
                style={{ minWidth: "90%" }}
            >
                <div
                    className="position-absolute top-0 end-0 me-3 mt-2 fe fe-x-circle"
                    style={{ height: "20px", width: "20px" }}
                    onClick={() => props.onCloseClicked()}
                ></div>
                <Row>
                    <h2>
                        Create Keyword For :{" "}
                        {props.service.serviceDetailsOverview.name}
                    </h2>
                </Row>
                <Row className="w-50">
                    <Form.Control
                        type="text"
                        placeholder="Keyword"
                        onChange={(e)=>handleKeywordChange(e.target.value)}
                    />
                    <Form.Control
                        type="text"
                        placeholder="Description"
                        onChange={(e)=>handleKeywordDescriptionChange(e.target.value)}
                        />

                    <Form.Control
                        type="datetime-local"
                        placeholder=""
                        onChange={(d)=>handleActiveFromChange(d.target.valueAsNumber/1000)}
                    ></Form.Control>
                    <Form.Select onChange={(e)=>handleTemplateSelectChange(e.target.value)}>
                        <option  value={0} key={0}>No Template Selected</option>
                        {templates
                            ? templates.content.map((t) => {
                                  return (
                                      <option value={t.id} key={t.id}>
                                          {t.name}
                                      </option>
                                  );
                              })
                            : ""}
                    </Form.Select>
                    <Form.Control
                        type="number"
                        placeholder="Entries Required For Free Entries"
                        onChange={(d)=>handleEntriesRequiredChange(d.target.value)}
                    ></Form.Control>
                    <Form.Control
                        type="number"
                        placeholder="Free Entries"
                        onChange={(d)=>handleFreeEntriesChange(d.target.value)}
                    ></Form.Control>
                    <Button variant="primary" type="submit" onClick={()=>{
                        submitForm()
                    }}>
                        Create
                    </Button>
                </Row>
            </div>
        </div>
    );
}

export default CreateKeywordPopUp;
