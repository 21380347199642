import React, { useEffect } from "react";
import Date from "../home/date/Date";
import CustomerSelector from "../home/selector/CustomerSelector";
import { PropTypes } from "prop-types";
import { useDispatch } from "react-redux";
import { getCustomersForUserFromApi } from "../../rdx/slices/customerSlice";
import { Button } from "react-bootstrap";

const StatsPage = (props) => {
    const { title, pretitle, render, innerNav } = props;
    const dispatch = useDispatch();
    const [selectedInnerNavComponent, setComponent] = React.useState(
        innerNav ? innerNav[0].component : render
    );

    /**
     * This UseEffect runs once on the start of the Page.
     * The customers of the user that they can access are fetched.
     * Once recieved, the first in the list is 'selected' so the data is populated rather
     * than showing a blank screen on load.
     */
    useEffect(() => {
        dispatch(getCustomersForUserFromApi());
    }, []);

    let buttons = "";
    if (innerNav) {
        buttons = props.innerNav.map((innav) => {
            return (
                <Button
                    id={innav.text}
                    className="mx-3"
                    style={{marginTop:'.750rem'}}
                    key={innav.text}
                    onClick={() => setComponent(innav.component)}
                >
                    {innav.text}
                </Button>
            );
        });
    }

    return (
        <div className="main-content statsPage">
            <div className="header">
                <div className="container-fluid">
                    <div className="header-body">
                        <div className="row justify-content-between">
                            <div
                                className="col-md-4 col-sm-12 align-self-center"
                                //align='start'
                            >
                                <h6 className="header-pretitle">{pretitle}</h6>
                                <h1 className="header-title">{title}</h1>
                                {buttons}
                            </div>
                            <div className="col-md-4 col-sm-12 align-self-end">
                                <div className="row">
                                    <CustomerSelector></CustomerSelector>
                                </div>
                                <div className="row justify-content-around">
                                    <div
                                        className="col-12" //align='end'
                                    >
                                        <Date
                                            format={"cccc dd LLLL"}
                                            zone="Europe/London"
                                        ></Date>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* {render} */}
            {selectedInnerNavComponent}
        </div>
    );
};

StatsPage.propTypes = {
    title: PropTypes.string.isRequired,
    pretitle: PropTypes.string.isRequired,
    render: PropTypes.object,
    innerNav: PropTypes.array,
};

export default StatsPage;
