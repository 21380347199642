import React from "react";
import { PropTypes } from "prop-types";
//import { DateTime } from 'luxon';
const ActivityRow = (props) => {
    // const startFormatted  =DateTime.fromSeconds(props.data.competitionWindowStartUnix).toFormat("DD HH:mm:ss ZZZZ",{zone:"Europe/London",locale:'en-GB'});
    // const endFormatted  =DateTime.fromSeconds(props.data.competitionWindowEndUnix).toFormat("DD HH:mm:ss ZZZZ",{zone:"Europe/London",locale:'en-GB'});
    // const entryTime  =DateTime.fromSeconds(props.data.entryTimeUnix).toFormat("DD HH:mm:ss ZZZZ",{zone:"Europe/London",locale:'en-GB'});

    // {"callId":"1729871565.665643","callDetails":{"ddi":"1729871565.665643","msisdn":"07897921388","callTime":1729871565},"entryDetails":{"serviceName":"GB News: Live Competition","serviceId":2,"keyword":"GBIVR","keywordId":2,"status":"SUCCESS","isFree":false,"createdAt":1729871566}}
    return props.data ? (
        <tr>
            <td>{props.data.callId}</td>
            <td>{props.data.entryDetails.serviceName}</td>
            <td>{props.data.entryDetails.keyword}</td>
            <td>{props.data.entryDetails.status }</td>
            <td>{props.data.callDetails.ddi }</td>
            <td>{props.data.callDetails.msisdn==""?"Anonymous":props.data.callDetails.msisdn }</td>
            <td>{props.data.entryDetails.isFree?'FREE':'PAID'}</td>
            <td>{new Date(props.data.entryDetails.createdAt*1000).toLocaleString() }</td>
        </tr>
    ) : (
        "ERROR"
    );
};

ActivityRow.propTypes = {
    data: PropTypes.object.isRequired
};

export default ActivityRow;
