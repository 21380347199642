import React from "react";
import { PropTypes } from "prop-types";
import { Button } from "react-bootstrap";
//import { DateTime } from 'luxon';
const ServiceRow = (props) => {
    // const startFormatted  =DateTime.fromSeconds(props.data.competitionWindowStartUnix).toFormat("DD HH:mm:ss ZZZZ",{zone:"Europe/London",locale:'en-GB'});
    // const endFormatted  =DateTime.fromSeconds(props.data.competitionWindowEndUnix).toFormat("DD HH:mm:ss ZZZZ",{zone:"Europe/London",locale:'en-GB'});
    // const entryTime  =DateTime.fromSeconds(props.data.entryTimeUnix).toFormat("DD HH:mm:ss ZZZZ",{zone:"Europe/London",locale:'en-GB'});
    return (
        <tr>
			<td>{props.data.serviceDetailsOverview.id}</td>
            <td>{props.data.serviceDetailsOverview.name}</td>
            <td>{props.data.serviceDetailsOverview.ddi}</td>
            <td>{props.data.serviceDetailsOverview.shortcode}</td>
            <td>{props.data.serviceDetailsOverview.activeKeyword}</td>
            <td>{props.data.serviceDetailsOverview.apiKeyName}</td>
            <td>
            <Button style={{cursor:'pointer'}} variant='primary' onClick={()=>props.onInfoClicked()}>Details</Button>
            </td>
        </tr>
    );
};

ServiceRow.propTypes = {
    data: PropTypes.object.isRequired,
    onInfoClicked:PropTypes.func.isRequired
};

export default ServiceRow;
