import React, { useEffect } from "react";
import { Button, Col, FormControl, Row, Table } from "react-bootstrap";
import TableHeader from "../../common/TableHeader";
import { useSelector } from "react-redux";
import { selectSelectedCustomer } from "../../../rdx/slices/customerSlice";
import axios from "../../../axios/axios";
import { useSnackbar } from "notistack";
import ActivityRow from "./ActivityRow";

const ActivityTable = () => {
    const selectedCustomer = useSelector(selectSelectedCustomer);

    useEffect(() => {
        if (selectedCustomer) {
            setSearch({msisdn:'',keyword:''})
            getData(0);
        }
    }, [selectedCustomer]);


    const [search, setSearch] = React.useState({ msisdn: "", keyword: "" });
    const [data, setData] = React.useState([]);
    const [sortBy, setSortBy] = React.useState({ sort: "createdAt", dir: "DESC" });
    const [paging, setPaging] = React.useState({
        page: 0,
        totalPages: 0,
    });

    useEffect(() => {}, []);

    const snackbar = useSnackbar();

    function getData(page) {
        axios
            .get(
                `/fonix-ivr-entries/activity?customer=${
                    selectedCustomer.id
                }&size=10&page=${page ? page : 0}&sort=${sortBy.sort},${
                    sortBy.dir
                }&serviceIds=&keyword=${
                    search.keyword
                }&keywordIds=&ddi=&msisdn=${search.msisdn}&status=`
            )
            .then((ok) => {
                const data = ok.data;
                setData(ok.data.content);
                setPaging({
                    ...paging,
                    page: data.page.number,
                    size: data.page.size,
                    totalPages: data.page.totalPages,
                });
            })
            .catch((err) => {
                snackbar.enqueueSnackbar(
                    `Error : ${err.response ? err.response.data : "???"}`,
                    {
                        variant: "error",
                    }
                );
            });
    }

    useEffect(() => {
        if (sortBy.sort) {
            if (selectedCustomer) {
                getData(paging.page);
            }
        }
    }, [sortBy]);

    useEffect(() => {}, [data]);

    // function onSortHeader(it) {
    //     if (sortBy.sort == it) {
    //         setSortyBy({ sort: it, dir: sortBy.dir == "ASC" ? "DESC" : "ASC" });
    //     } else {
    //         setSortyBy({ sort: it, dir: "DESC" });
    //     }
    // }

    const pageElements = () => {
        let render = [];
        const formatPageElement = (pageNumber, active) => {
            return (
                <li
                    key={`page-${pageNumber}`}
                    className={active ? "active" : ""}
                    onClick={() => getData(pageNumber)}
                >
                    <button className="page">{pageNumber + 1}</button>
                </li>
            );
        };

        for (
            var i = Math.max(paging.page - 5, 0);
            i < Math.min(Math.max(paging.page - 5, 0) + 10, paging.totalPages);
            i++
        ) {
            const active = paging.page === i;
            render.push(formatPageElement(i, active));
        }

        return render;
    };

    return (
        <div>
            <div className="container-fluid tableContainer">
                <div className="card" style={{ overflow: "auto" }}>
                    <div className="card-header">
                        <Row className="align-items-center justify-content-around">
                            <Col lg={3}>
                                <h3 className="card-header-title">
                                    Web Entry Activity
                                </h3>
                            </Col>
                            <Col>
                                <Row className="align-items-end justify-content-around">
                                    <Col>
                                        <Row className="mx-1">
                                            <FormControl
                                                type="text"
                                                placeholder="MSISDN"
                                                id="msisdnSearchBox"
                                                className="searchbox"
                                                onChange={(e) =>
                                                    setSearch({
                                                        ...search,
                                                        msisdn: e.target.value,
                                                    })
                                                }
                                            />
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row className="mx-1">
                                            <FormControl
                                                type="text"
                                                id="keywordSearchBox"
                                                className="searchbox"
                                                placeholder="KEYWORD"
                                                onChange={(e) =>
                                                    setSearch({
                                                        ...search,
                                                        keyword: e.target.value,
                                                    })
                                                }
                                            />
                                        </Row>
                                    </Col>

                                    <Col>
                                        <Button
                                            type="submit"
                                            value="Search"
                                            onClick={() => getData(0)}
                                        >
                                            Search
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>

                    <Table
                        className="table table-sm table-nowrap card-table"
                        responsive="sm"
                    >
                        <thead>
                            <tr>
                                <TableHeader
                                    id={"id"}
                                    name="ID"
                                    onSort={() =>
                                        setSortBy({
                                            sort: "callId",
                                            dir:
                                                sortBy.dir == "ASC"
                                                    ? "DESC"
                                                    : "ASC",
                                        })
                                    }
                                />
                                <TableHeader
                                    id={"service.name"}
                                    name="SERVICE"
                                    onSort={() =>
                                        setSortBy({
                                            sort: "service.name",
                                            dir:
                                                sortBy.dir == "ASC"
                                                    ? "DESC"
                                                    : "ASC",
                                        })
                                    }
                                />
                                <TableHeader
                                    id={"keyword.keyword"}
                                    name="KEYWORD"
                                    onSort={() =>
                                        setSortBy({
                                            sort: "keyword",
                                            dir:
                                                sortBy.dir == "ASC"
                                                    ? "DESC"
                                                    : "ASC",
                                        })
                                    }
                                />
                                <TableHeader
                                    id={"entryStatus"}
                                    name="STATUS"
                                    onSort={() =>
                                        setSortBy({
                                            sort: "entryStatus",
                                            dir:
                                                sortBy.dir == "ASC"
                                                    ? "DESC"
                                                    : "ASC",
                                        })
                                    }
                                />
                                <TableHeader
                                    id={"service.ddi"}
                                    name="DDI"
                                    onSort={() =>
                                        setSortBy({
                                            sort: "service.ddi",
                                            dir:
                                                sortBy.dir == "ASC"
                                                    ? "DESC"
                                                    : "ASC",
                                        })
                                    }
                                />
                                <TableHeader
                                    id={"caller.msisdn"}
                                    name="MSISDN"
                                    onSort={() =>
                                        setSortBy({
                                            sort: "caller",
                                            dir:
                                                sortBy.dir == "ASC"
                                                    ? "DESC"
                                                    : "ASC",
                                        })
                                    }
                                />
                                <TableHeader
                                    id={"freeEntry"}
                                    name="TYPE"
                                    onSort={() =>
                                        setSortBy({
                                            sort: "freeEntry",
                                            dir:
                                                sortBy.dir == "ASC"
                                                    ? "DESC"
                                                    : "ASC",
                                        })
                                    }
                                />
                                <TableHeader
                                    id={"createdAt"}
                                    name="CREATED"
                                    onSort={() =>
                                        setSortBy({
                                            sort: "createdAt",
                                            dir:
                                                sortBy.dir == "ASC"
                                                    ? "DESC"
                                                    : "ASC",
                                        })
                                    }
                                />
                            </tr>
                        </thead>
                        <tbody>
                            {data
                                ? data.map((it, x) => (
                                      <ActivityRow
                                          id={x}
                                          key={it.callId}
                                          data={it}
                                      >
                                          {" "}
                                      </ActivityRow>
                                  ))
                                : ""}
                        </tbody>
                    </Table>
                    <div className="card-footer d-flex justify-content-between">
                        {paging.page > 0 ? (
                            <ul className="list-pagination-prev pagination pagination-tabs card-pagination">
                                <li className="page-item">
                                    <button
                                        className="page-link ps-0 pe-4 border-end"
                                        onClick={() => getData(paging.page - 1)}
                                    >
                                        <i className="fe fe-arrow-left me-1"></i>{" "}
                                        Prev
                                    </button>
                                </li>
                            </ul>
                        ) : (
                            <ul></ul>
                        )}
                        <ul className="list-pagination pagination pagination-tabs card-pagination">
                            {pageElements()}
                        </ul>
                        {paging.page < paging.totalPages - 1 ? (
                            <ul className="list-pagination-next pagination pagination-tabs card-pagination">
                                <li className="page-item">
                                    <button
                                        className="page-link ps-4 pe-0 border-start"
                                        onClick={() => getData(paging.page + 1)}
                                    >
                                        Next{" "}
                                        <i className="fe fe-arrow-right ms-1"></i>
                                    </button>
                                </li>
                            </ul>
                        ) : (
                            <ul></ul>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ActivityTable;
