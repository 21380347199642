import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import TableHeader from "../../common/TableHeader";
import ServiceRow from "./ServiceRow";
import { useSelector } from "react-redux";
import { selectSelectedCustomer } from "../../../rdx/slices/customerSlice";
import axios from "../../../axios/axios";
import { useSnackbar } from "notistack";
import CreateServicePopUp from "./CeateServicePopUp";
import ServiceInfoPopUp from "./ServiceInfoPopUp";

const ServicesTable = () => {
    const selectedCustomer = useSelector(selectSelectedCustomer);

    useEffect(() => {
        if (selectedCustomer) {
            getData(0);
        }
    }, [selectedCustomer]);

    const [data, setData] = React.useState([]);
    const [sortBy, setSortyBy] = React.useState({ sort: "id", dir: "ASC" });
    const [paging, setPaging] = React.useState({
        page: 0,
        totalPages: 0,
    });

    useEffect(() => {}, []);

    const [showCreatePopUp, setShowCreatePopUp] = useState(false);
    const [showServiceInfoPopUp, setShowServiceInfoPopUp] = useState();

    function toggleShowCreatePopUp() {
        setShowCreatePopUp(!showCreatePopUp);
    }

    function toggleShowServiceInfoPopUp(data) {
        setShowServiceInfoPopUp(data);
    }

    const snackbar = useSnackbar();

    function getData(page) {
        axios
            .get(
                `/fonix-ivr-entries/customers/${
                    selectedCustomer.id
                }/services?size=10&page=${page ? page : 0}&sort=${
                    sortBy.sort
                },${sortBy.dir}`
            )
            .then((ok) => {
                const data = ok.data;
                setData(ok.data.content);
                setPaging({
                    ...paging,
                    page: data.number,
                    size: data.size,
                    totalPages: data.totalPages,
                });
            })
            .catch((err) => {
                snackbar.enqueueSnackbar(
                    `Error : ${err.response ? err.response.data : "???"}`,
                    {
                        variant: "error",
                    }
                );
            });
    }

    useEffect(() => {
        if (sortBy.sort) {
            if (selectedCustomer) {
                getData(paging.page);
            }
        }
    }, [sortBy]);

    useEffect(() => {}, [data]);

    function onSortHeader(it) {
        if (sortBy.sort == it) {
            setSortyBy({ sort: it, dir: sortBy.dir == "ASC" ? "DESC" : "ASC" });
        } else {
            setSortyBy({ sort: it, dir: "DESC" });
        }
    }

    const pageElements = () => {
        let render = [];
        const formatPageElement = (pageNumber, active) => {
            return (
                <li
                    key={`page-${pageNumber}`}
                    className={active ? "active" : ""}
                    onClick={() => getData(pageNumber)}
                >
                    <button className="page">{pageNumber + 1}</button>
                </li>
            );
        };

        for (
            var i = Math.max(paging.page - 5, 0);
            i < Math.min(Math.max(paging.page - 5, 0) + 10, paging.totalPages);
            i++
        ) {
            const active = paging.page === i;
            render.push(formatPageElement(i, active));
        }

        return render;
    };

    return (
        <div>
            {showCreatePopUp ? (
                <CreateServicePopUp
                    onCloseClicked={() => toggleShowCreatePopUp()}
                />
            ) : (
                ""
            )}
            {showServiceInfoPopUp ? (
                <ServiceInfoPopUp
                    onCloseClicked={() => toggleShowServiceInfoPopUp()}
                    data={showServiceInfoPopUp}
                />
            ) : (
                ""
            )}
            <div className="container-fluid tableContainer">
                <div className="card" style={{ overflow: "auto" }}>
                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col">
                                <h3 className="card-header-title">
                                    Web Entry Services
                                </h3>
                            </div>
                            <div
                                className="col d-flex"
                                style={{
                                    alignContent: "end",
                                    justifyContent: "end",
                                }}
                            ></div>
                        </div>
                    </div>

                    <Table
                        className="table table-sm table-nowrap card-table"
                        responsive="sm"
                    >
                        <thead>
                            <tr>
                                <TableHeader
                                    id={"id"}
                                    name="ID"
                                    onSort={onSortHeader}
                                />
                                <TableHeader
                                    id={"name"}
                                    name="NAME"
                                    onSort={onSortHeader}
                                />
                                <TableHeader
                                    id={"ddi"}
                                    name="DDI"
                                    onSort={onSortHeader}
                                />
                                <TableHeader
                                    id={"shortcode"}
                                    name="SHORTCODE"
                                    onSort={onSortHeader}
                                />
                                <TableHeader
                                    id={"keywords.keyword"}
                                    name="KEYWORD"
                                    // onSort={onSortHeader}
                                />
                                <TableHeader
                                    id={"apiKey.name"}
                                    name="API Key"
                                    onSort={onSortHeader}
                                />
                                <TableHeader
                                    id={"details"}
                                    name=""
                                  //  onSort={onSortHeader}
                                />
                            </tr>
                        </thead>
                        <tbody>
                            {data
                                ? data.map((it, x) => (
                                      <ServiceRow
                                          id={x}
                                          key={x}
                                          data={it}
                                          onInfoClicked={() =>
                                              toggleShowServiceInfoPopUp(it)
                                          }
                                      />
                                  ))
                                : ""}
                        </tbody>
                    </Table>
                    <div className="card-footer d-flex justify-content-between">
                        {paging.page > 0 ? (
                            <ul className="list-pagination-prev pagination pagination-tabs card-pagination">
                                <li className="page-item">
                                    <button
                                        className="page-link ps-0 pe-4 border-end"
                                        onClick={() => getData(paging.page - 1)}
                                    >
                                        <i className="fe fe-arrow-left me-1"></i>{" "}
                                        Prev
                                    </button>
                                </li>
                            </ul>
                        ) : (
                            <ul></ul>
                        )}
                        <ul className="list-pagination pagination pagination-tabs card-pagination">
                            {pageElements()}
                        </ul>
                        {paging.page < paging.totalPages - 1 ? (
                            <ul className="list-pagination-next pagination pagination-tabs card-pagination">
                                <li className="page-item">
                                    <button
                                        className="page-link ps-4 pe-0 border-start"
                                        onClick={() => getData(paging.page + 1)}
                                    >
                                        Next{" "}
                                        <i className="fe fe-arrow-right ms-1"></i>
                                    </button>
                                </li>
                            </ul>
                        ) : (
                            <ul></ul>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServicesTable;
